import { createActionRequestWithAuth } from '../utils';
import {
  FETCH_EVENTS_COUNT,
  FETCH_EVENTS_COUNT_SUCCESS,
  FETCH_EVENTS_LIST,
  FETCH_EVENTS_LIST_SUCCESS,
  CREATE_EVENT,
  EDIT_EVENT,
  DELETE_EVENT,
  GET_EVENT_BY_ID
} from './constants';

export const fetchEventsCount = (query = '') => async(dispatch) => {
  dispatch({ type: FETCH_EVENTS_COUNT });
  const response = await dispatch(createActionRequestWithAuth({
    type: FETCH_EVENTS_COUNT,
    request: {
      method: 'GET',
      endpoint: `events/cnt/?q=${query}`
    }
  }));

  if (response?.request) {
    dispatch({ type: FETCH_EVENTS_COUNT_SUCCESS, payload: response.request.response.data });
  }
};

export const fetchEventsList = (query = '', limit = 10, offset = 0, order = 'date') => async(dispatch) => {
  dispatch({ type: FETCH_EVENTS_LIST });

  const response = await dispatch(createActionRequestWithAuth({
    type: FETCH_EVENTS_LIST,
    request: {
      method: 'GET',
      endpoint: `events/get/?q=${query}&limit=${limit}&offset=${offset}&order=${order}`
    }
  }));
  if (response?.request) {
    dispatch({ type: FETCH_EVENTS_LIST_SUCCESS, payload: response.request.response.data });
  }
};

export const createEvent = ({ eventName, eventDate }) => async(dispatch) => dispatch(createActionRequestWithAuth({
  type: CREATE_EVENT,
  request: {
    method: 'POST',
    endpoint: 'events/create-event/',
    data: {
      title: eventName,
      date: eventDate
    }
  }
}));

export const editEvent = ({ eventName, eventDate, id }) => async(dispatch) => dispatch(createActionRequestWithAuth({
  type: EDIT_EVENT,
  request: {
    method: 'PUT',
    endpoint: `events/edit-event/${id}`,
    data: {
      title: eventName,
      date: eventDate
    }
  }
}));

export const deleteEvent = (id) => async(dispatch) => dispatch(createActionRequestWithAuth({
  type: DELETE_EVENT,
  request: {
    method: 'DELETE',
    endpoint: `events/delete-event/${id}`
  }
}));

export const getEventById = (id) => async(dispatch) => dispatch(createActionRequestWithAuth({
  type: GET_EVENT_BY_ID,
  request: {
    method: 'GET',
    endpoint: `events/get/${id}`
  }
})).then((action) => action?.request?.response?.data);
