/* eslint-disable max-len */
import { FETCH_EVENTS_COUNT_FAILURE } from '../events/constants';
import { createActionRequestWithAuth } from '../utils';

import {
  UPLOAD_IMAGE,
  UPLOAD_VIDEO,
  CREATE_TARGETS,
  RESET_STATE,
  GET_TARGET,
  GET_TARGETS_LIST,
  LOAD_IMAGE,
  UPDATE_TARGETS,
  DELETE_TARGET,
  UPDATE_SESSION_NAME,
  SET_TARGET_FIELDS,
  SET_TARGET_IMAGE_FOR_CREATION,
  SET_TARGET_VIDEO_FOR_CREATION,
  LOAD_VIDEO,
  FETCH_TARGETS_COUNT,
  FETCH_TARGETS_COUNT_SUCCESS,
  UPDATE_EVENT_NAME,
  GET_TARGET_BY_SESSION_ID,
  FETCH_SESSIONS_COUNT_REQUEST,
  FETCH_SINGLE_TARGETS,
  FETCH_SESSION_TARGETS,
  FETCH_SINGLE_SESSIONS_COUNT,
  FETCH_SESSION_SESSIONS_COUNT,
  FETCH_ALL_TARGETS,
  REMOVE_FILE_FROM_UPLOAD,
  FETCH_ALL_SESSION_COUNT,
  INSTANT_DELETE_SESSION,
  FETCH_SINGLE_TARGETS_COUNT,
  FETCH_SESSION_TARGETS_COUNT
} from './constants';

export const uploadTargetFile = ({ file }) => {
  if (file?.type.includes('image')) {
    return (dispatch) => dispatch(createActionRequestWithAuth({
      type: UPLOAD_IMAGE,
      request: {
        method: 'POST',
        endpoint: 'targets/upload-photo/',
        contentType: 'form-data',
        data: [
          {
            name: 'photo',
            value: file
          }
        ]
      }
    }));
  }
  return (dispatch) => dispatch(createActionRequestWithAuth({
    type: UPLOAD_VIDEO,
    request: {
      method: 'POST',
      endpoint: 'targets/upload-video/',
      contentType: 'form-data',
      data: [
        {
          name: 'video',
          value: file
        }
      ]
    }
  }));
};

export const loadImage = (image) => ({
  type: LOAD_IMAGE,
  payload: image
});

export const loadVideo = (video) => ({
  type: LOAD_VIDEO,
  payload: video
});

export const removeFileFromUpload = (fileName) => ({
  type: REMOVE_FILE_FROM_UPLOAD,
  payload: fileName
});

export const updateSessionName = (name) => ({
  type: UPDATE_SESSION_NAME,
  payload: name
});

export const updateEventName = (id) => ({
  type: UPDATE_EVENT_NAME,
  payload: id
});

export const updateTargets = (targets) => ({
  type: UPDATE_TARGETS,
  payload: targets
});

export const createTargets = (data) => (dispatch) => {
  if (data === null || data === undefined) {
    return;
  }
  dispatch(createActionRequestWithAuth({
    type: CREATE_TARGETS,
    request: {
      method: 'POST',
      endpoint: 'targets/create-targets/',
      contentType: 'multipart/form-data',
      data
    }
  }));
};

export const updateDataTargets = (data) => (dispatch) => {
  if (data === null || data === undefined) {
    return;
  }
  dispatch(createActionRequestWithAuth({
    type: UPDATE_TARGETS,
    request: {
      method: 'PATCH',
      endpoint: 'targets/edit-targets/',
      contentType: 'multipart/form-data',
      data
    }
  }));
};

export const resetState = () => ({
  type: RESET_STATE
});

export const getTargetsList = (params) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: GET_TARGETS_LIST,
  request: {
    method: 'GET',
    endpoint: `targets/get-targets?${new URLSearchParams(params)}`
  }
}));

export const getAllTargetsList = (params) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: FETCH_ALL_TARGETS,
  request: {
    method: 'GET',
    endpoint: `targets/get-targets?${new URLSearchParams(params)}`
  }
}));

export const getSingleTargetsList = (params) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: FETCH_SINGLE_TARGETS,
  request: {
    method: 'GET',
    endpoint: `targets/get-targets?${new URLSearchParams(params)}`
  }
}));

export const getSessionTargetsList = (params) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: FETCH_SESSION_TARGETS,
  request: {
    method: 'GET',
    endpoint: `targets/get-targets?${new URLSearchParams(params)}`
  }
}));

export const getTarget = (targetId) => (dispatch) => dispatch(createActionRequestWithAuth({
  type: GET_TARGET,
  request: {
    method: 'GET',
    endpoint: `target/${targetId}/`
  }
}));

export const deleteTarget = (query) => (dispatch) => {
  const queryParams = new URLSearchParams(query).toString();
  if (query.sessionId) {
    dispatch({ type: INSTANT_DELETE_SESSION, payload: query.sessionId });
  }
  return dispatch(createActionRequestWithAuth({
    type: DELETE_TARGET,
    request: {
      method: 'DELETE',
      endpoint: `targets/delete-target/?${queryParams}`
    }
  }));
};

export const deleteAsset = (url) => async(dispatch) => dispatch(createActionRequestWithAuth({
  type: FETCH_ALL_SESSION_COUNT,
  request: {
    method: 'DELETE',
    endpoint: 'targets/delete-media/',
    contentType: 'form-data',
    data:
    {
      name: 'url',
      value: url
    }
  }
}));

export const getTargetsBySessionId = (sessionId, eventId, isSingle) => (dispatch) => {
  const params = { sessionId, isSingle };
  if (eventId) {
    params.eventId = eventId;
  }
  return dispatch(createActionRequestWithAuth({
    type: GET_TARGET_BY_SESSION_ID,
    request: {
      method: 'GET',
      endpoint: `targets/get-targets/?${new URLSearchParams(params)}`
    }
  }));
};

export const setTargetFields = (target) => ({
  type: SET_TARGET_FIELDS,
  target
});

export const setTargetImageForCreation = (image) => ({
  type: SET_TARGET_IMAGE_FOR_CREATION,
  image
});

export const setTargetVideoForCreation = (video) => ({
  type: SET_TARGET_VIDEO_FOR_CREATION,
  video
});

export const fetchTargetsCount = (filters = {}) => async(dispatch) => {
  const queryParams = new URLSearchParams(filters).toString();
  const response = await dispatch(createActionRequestWithAuth({
    type: FETCH_TARGETS_COUNT,
    request: {
      method: 'GET',
      endpoint: `targets/count-targets/?${queryParams}`
    }
  }));

  if (response?.request) {
    return dispatch({ type: FETCH_TARGETS_COUNT_SUCCESS, payload: response.request.response.data });
  }
  return dispatch({ type: FETCH_EVENTS_COUNT_FAILURE });
};

export const fetchSingleTargetsCount = (filters = {}) => async(dispatch) => dispatch(createActionRequestWithAuth({
  type: FETCH_SINGLE_TARGETS_COUNT,
  request: {
    method: 'GET',
    endpoint: `targets/count-targets/?${new URLSearchParams(filters).toString()}`
  }
}));

export const fetchSessionTargetsCount = (filters = {}) => async(dispatch) => dispatch(createActionRequestWithAuth({
  type: FETCH_SESSION_TARGETS_COUNT,
  request: {
    method: 'GET',
    endpoint: `targets/count-targets/?${new URLSearchParams(filters).toString()}`
  }
}));

export const fetchSessionsCount = (filters = {}) => async(dispatch) => dispatch(createActionRequestWithAuth({
  type: FETCH_SESSIONS_COUNT_REQUEST,
  request: {
    method: 'GET',
    endpoint: `targets/count-sessions/?${new URLSearchParams(filters).toString()}`
  }
}));

export const fetchAllSessionsCount = (filters = {}) => async(dispatch) => dispatch(createActionRequestWithAuth({
  type: FETCH_ALL_SESSION_COUNT,
  request: {
    method: 'GET',
    endpoint: `targets/count-sessions/?${new URLSearchParams(filters).toString()}`
  }
}));

export const fetchSingleSessionsCount = (filters = {}) => async(dispatch) => dispatch(createActionRequestWithAuth({
  type: FETCH_SINGLE_SESSIONS_COUNT,
  request: {
    method: 'GET',
    endpoint: `targets/count-sessions/?${new URLSearchParams(filters).toString()}`
  }
}));

export const fetchSessionSessionsCount = (filters = {}) => async(dispatch) => dispatch(createActionRequestWithAuth({
  type: FETCH_SESSION_SESSIONS_COUNT,
  request: {
    method: 'GET',
    endpoint: `targets/count-sessions/?${new URLSearchParams(filters).toString()}`
  }
}));
