import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import EventItem from '../EventItem/EventItem';
import { selectEventsList } from '../../redux/events/selectors';
import { fetchEventsCount, fetchEventsList } from '../../redux/events/actions';
import Loader from '../UI/Loader/Loader';

import { noopPromise } from '../../utils';

import style from './EventList.scss';

const EventList = ({ limit, onMount }) => {
  const dispatch = useDispatch();
  const events = useSelector(selectEventsList);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, [onMount, events]);

  useEffect(() => {
    const fetchData = async() => {
      try {
        setIsLoading(true);
        await dispatch(fetchEventsCount());
        await dispatch(fetchEventsList('', 10, 0, 'id'));
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const displayedEvents = limit ? events.slice(0, limit) : events;

  return (
    <ul className={style.listHolder}>
      {isLoading && (
        <Loader />
      )}
      {!isLoading && displayedEvents.map(({
        id, title, date, targets, eventViews
      }) => (
        <EventItem
          key={id}
          id={id}
          title={title}
          views={eventViews}
          date={date}
          targets={targets}
        />
      ))}
    </ul>
  );
};

EventList.propTypes = {
  limit: PropTypes.number,
  onMount: PropTypes.func
};

EventList.defaultProps = {
  limit: undefined,
  onMount: noopPromise
};

export default memo(EventList);
